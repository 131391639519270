import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  orders: [] ,
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    get: (state, action) => {
      state.orders = action.payload
    },
    save: (state, action) => {
      let indexGuard = state.orders.findIndex((g)=>(g.id === action.payload.id))
      state.orders[indexGuard] = action.payload
    },
  },
})

export const { get, save } = orderSlice.actions
export default orderSlice.reducer