import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'

const FormGuard = (props) => {
  const guardState = useSelector((state) => state.inventory)
  const [formValues, setFormValues] = useState({
    name: props.name,
    description: props.description,
    yield: props.yield,
    measure_id: props.measure_id
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Valor requerido").max(20, "Nombre debe ser máximo de 20 caracteres"),
    description: Yup.string().required("Valor requerido"),
    yield: Yup.string().required("Valor requerido").min(0, "el valor minimo es 0"),
    measure_id: Yup.string().required("Valor requerido").min(0, "el valor minimo es 0"),
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value

    })
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Nombre Formula</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="name"
                controlid="name"
                placeholder="Nombre"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.name && !!errors.name}
              />
              <FormControl.Feedback type="invalid">
                {errors.name}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup className="mb-3" controlId="textarea-form">
              <FormLabel>Descripción</FormLabel>
              <Field
                name="description"
                controlid="description"
                as="textarea"
                className={"form-control"}
                rows={4}
                autoComplete={"off"}
                placeholder="Enter your text here"
                onInput={handleAutofillChange}

              />
              <FormControl.Feedback type="invalid">
                {errors.description}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup className="mb-3" controlId="guard-name-account-form">
              <FormLabel>Unidad de medida</FormLabel>
              <Field
                name="measure_id"
                isInvalid={touched.measure_id && !!errors.measure_id}
              >
                {({ field, form }) => (
                  <select
                    className={"form-control"}
                    value={field.value}
                    controlid="measure_id"
                    placeholder="Unidad de Medida"
                    autoComplete={"off"}
                    onChange={(event) => {
                      form.setFieldValue(field.name, event.target.value);
                    }}
                  >
                    <option value="">Seleccione un tipo</option>
                    {guardState !== undefined && guardState.measure.map((g, idx)=> (<option key={`opti-${idx}`} value={g.id}>{g.name}</option>))}
                  </select>
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.measure_id}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Peso</FormLabel>
              <FormControl
                type="number"
                as={Field}
                name="yield"
                controlid="yield"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.yield && !!errors.yield}
              />
              <FormControl.Feedback type="invalid">
                {errors.yield}
              </FormControl.Feedback>
            </FormGroup>

            <Button variant="success" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner />}
              Almacenar
            </Button>
          </Form>
        )}
      </Formik>

}

export default FormGuard