import Accordion from 'react-bootstrap/Accordion'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import {AiOutlineEdit, AiOutlineSave, AiFillCheckCircle} from 'react-icons/ai'
import { MdError } from 'react-icons/md'
import {useState, useMemo, useCallback, useEffect} from 'react'
import axios from 'axios'
import {  useSelector, useDispatch } from 'react-redux'
import {get} from "../../store/PackageSlice";
const BatchList = ({ batchs, reloadOrders, status }) => {
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.user)
    const packagesState = useSelector((state) => state.packages)
    const [editbatch, setEditBatch] = useState(0)
    const [newSerial, setNewSerial] = useState({})
    const [editexp, setEditExp] = useState(0)
    const [newPkg, setNewPkg] = useState({
        consumed_date:(new Date()).toISOString().split('T')[0],
        spoilt: false,
    })
    const [editPkg, setEditPkg] = useState(0)
    const [newExp, setNewExp] = useState({})
    const [toastData, setToastData] = useState({ title:"", message:"", variant:"success" })
    const [showToast, setShowToast] = useState( false )
    const [loading1, setLoading1] = useState( false )
    const [loading2, setLoading2] = useState( false )
    const [loading3, setLoading3] = useState( false )

    const configs = useMemo(()=>({
        headers:{
            'content-type': 'application/json',
            'Authorization': `Bearer ${userState.user.token}`,
            'Accept': `*/*`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
            'Access-Control-Allow-Headers': 'Content-Type',
            withCredentials: true,
        }
    }), [userState])
    const getData = useCallback(async ()=>{
        const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-package", configs)

        dispatch(get(response.data))
    },[dispatch, configs])
    const modifySerial = (evt) => {
        let newserial = {
            batch_serial: evt.target.value
        }
        setNewSerial(newserial)
    }
    const modifyExpiration = (evt) => {
        let newexp = {
            expiration_date: evt.target.value
        }
        setNewExp(newexp)
    }
    const modifyPkg = (evt) => {
        let newexp = {
            ...newPkg,
            [evt.target.name]: evt.target.value
        }
        setNewPkg(newexp)
    }
    const saveSerial = async() => {
        setLoading1(true)
        try {
            await axios.put(process.env.REACT_APP_USER_API_URL + "/order-item-batch/"+editbatch , newSerial, configs)
            await reloadOrders()
            setToastData({
                title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
                message: 'Proceso ejecutado satisfactoriamente!',
                variant: 'success',
            })
            setNewSerial({})
            setEditBatch(0)
        }catch (ex) {
            let errorMessage = ex.message
            if(ex.response?.data?.error !== undefined) {
                errorMessage = ex.response?.data?.error
            }
            setToastData({
                title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
                message: <>Se presentó un error en el proceso! <strong>{errorMessage}</strong></>,
                variant: 'danger',
            })
            setShowToast(true)
        }
        setLoading1(false)
    }

    const saveExpiration = async() => {
        setLoading2(true)
        try {
            await axios.put(process.env.REACT_APP_USER_API_URL + "/order-item-batch/"+editexp , newExp, configs)
            await reloadOrders()
            setToastData({
                title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
                message: 'Proceso ejecutado satisfactoriamente!',
                variant: 'success',
            })
            setNewExp({})
            setEditExp(0)
        }catch (ex) {
            let errorMessage = ex.message
            if(ex.response?.data?.error !== undefined) {
                errorMessage = ex.response?.data?.error
            }
            setToastData({
                title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
                message: <>Se presentó un error en el proceso! <strong>{errorMessage}</strong></>,
                variant: 'danger',
            })
            setShowToast(true)
        }
        setLoading2(false)
    }

    const saveConsumePackage = async() => {
        setLoading3(true)
        try {
            await axios.post(process.env.REACT_APP_USER_API_URL + "/inventory-package-consume", newPkg,configs)
            await reloadOrders()
            await getData()
            setToastData({
                title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
                message: 'Proceso ejecutado satisfactoriamente!',
                variant: 'success',
            })
            setNewPkg({
                consumed_date:(new Date()).toISOString().split('T')[0],
                spoilt: false,
            })
            setEditPkg(0)
        }catch (ex) {
            let errorMessage = ex.message
            if(ex.response?.data?.error !== undefined) {
                errorMessage = ex.response?.data?.error
            }
            setToastData({
                title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
                message: <>Se presentó un error en el proceso! <strong>{errorMessage}</strong></>,
                variant: 'danger',
            })
            setShowToast(true)
        }
        setLoading3(false)
    }

    useEffect(()=>{
        getData()
    },[getData,userState.user.token])
    return  <Accordion>
        <Accordion.Item eventKey="0">
            <Accordion.Header>Lote Producido</Accordion.Header>
            <Accordion.Body>
                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Serial Lote</th>
                        <th>Fecha de Vencimiento</th>
                        <th>Cantidad</th>
                        <th>Empaque</th>
                        <th>Creado</th>
                    </tr>
                    </thead>
                    <tbody>
                    {batchs.map((b, i)=><tr key={i}>
                        <td>{b.id}</td>
                        <td>
                            {editbatch != b.id &&  <>{b.batch_serial} {status != "Despachado" && <Button variant={"info"} size={"sm"}
                                                                               onClick={() => {
                                                                                   setEditBatch(b.id)
                                                                               }}><AiOutlineEdit/></Button>}</>}
                            {editbatch === b.id && <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Número de Lote"
                                    aria-label="Número de Lote"
                                    aria-describedby="basic-serial-batch"
                                    defaultValue={b.batch_serial}
                                    onInput={modifySerial}
                                    disabled={loading1}
                                />
                                <Button  variant="success" disabled={loading1} id="button-addon2" onClick={saveSerial}>
                                    {loading1 && <Spinner/>} <AiOutlineSave />
                                </Button>
                            </InputGroup>}
                        </td>
                        <td>
                            {editexp != b.id && <>{b.expiration_date} {status !="Despachado" && <Button variant={"info"} size={"sm"}
                                                                               onClick={() => {
                                                                                   setEditExp(b.id)
                                                                               }}><AiOutlineEdit/></Button>}</>}
                            {editexp === b.id && <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Fecha de Expiración"
                                    type={"date"}
                                    aria-label="Fecha de Expiración"
                                    aria-describedby="basic-serial-batch"
                                    defaultValue={b.expiration_date}
                                    onInput={modifyExpiration}
                                    disabled={loading2}
                                />
                                <Button variant="success" id="button-addon2"  disabled={loading2} title={"Almacenar"} onClick={saveExpiration}>
                                    {loading2 && <Spinner/>} <AiOutlineSave />
                                </Button>
                            </InputGroup>}
                        </td>
                        <td>{b.quantity}</td>
                        <td>
                            {parseInt(editPkg)  !== parseInt(b.id) && <>{status ==="Producto Sin Empaque" && <Button variant={"info"} size={"sm"}
                                                                                                       onClick={() => {
                                                                                                           setEditPkg(b.id)
                                                                                                           setNewPkg({
                                                                                                               ...newPkg,
                                                                                                               batch_id: b.id
                                                                                                           })
                                                                                                       }}><AiOutlineEdit/></Button>}</>}
                            {parseInt(editPkg)  !== parseInt(b.id) && b.packages.length > 0 && <Table striped bordered hover><thead>
                            <tr>
                                <th>Cant.</th>
                                <th>Empaque</th>
                                <th>und/emp</th>
                            </tr>
                            </thead><tbody>{b.packages.map((consumes)=><tr key={consumes.id}><td>{consumes.stock_consumed}</td><td>{consumes.package.package_name}</td><td>{consumes.package.units_by} </td></tr>
                            )}</tbody></Table>}
                            {parseInt(editPkg) === parseInt(b.id) && <InputGroup className="mb-3">
                                <Form.Control type="number" id={`input-pkg-${b.id}`} min={1} name={'stock_consumed'} onChange={modifyPkg}  />

                                <select
                                    className={'form-control'}
                                    controlid="package_id"
                                    name="package_id"
                                    placeholder="Empaque"
                                    autoComplete={'off'}
                                    onChange={(evt)=> {
                                        const pkgSelected = packagesState.packages.find((itm)=> parseInt(itm.id) === parseInt(evt.target.value))
                                        document.getElementById(`input-pkg-${b.id}`).max=pkgSelected.stock
                                        modifyPkg(evt)
                                    }}
                                >
                                    <option value="">Seleccione un Empaque</option>
                                    {packagesState.packages.map((pkg)=> {
                                        if(pkg.stock>0){
                                            return <option key={pkg.id}
                                                           value={pkg.id}>{pkg.package_name} [{pkg.units_by} unds]</option>
                                        }
                                    })}
                                </select>
                                <Button variant="success" id="button-addon2"  disabled={loading3} title={"Almacenar"} onClick={saveConsumePackage}>
                                    {loading3 && <Spinner/>} <AiOutlineSave />
                                </Button>
                            </InputGroup>
                            }
                        </td>
                        <td>
                            {(new Date(b.created_at)).toLocaleString()}
                        </td>
                    </tr>)}
                    </tbody>
                </Table>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default BatchList