import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  recipes: [],
}

export const recipeSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    get: (state, action) => {
      state.recipes = action.payload
    },
    save: (state, action) => {
      let index = state.recipes.findIndex((g)=>(g.id === action.payload.id))
      state.recipes[index] = action.payload
    },
  },
})

export const { get, save} = recipeSlice.actions
export default recipeSlice.reducer