import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  packages: [] ,
}

export const packageSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    get: (state, action) => {
      state.packages = action.payload
    },
    save: (state, action) => {
      let indexGuard = state.packages.findIndex((g)=>(g.id === action.payload.id))
      state.packages[indexGuard] = action.payload
    },
  },
})

export const { get, save } = packageSlice.actions
export default packageSlice.reducer