import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, FormCheck, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'

const FormRemoveStock = (props) => {
    const [formValues, setFormValues] = useState({
        package_id: props.packageId,
        consumed_date: null,
        spoilt: true,
        stock_consumed: 0
    });
    const validationSchema = Yup.object().shape({
        consumed_date: Yup.date().required("Valor requerido"),
        stock_consumed: Yup.number().default(0),
    });
    const handleAutofillChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        setFormValues(values)
        await props.handleSubmit(values)
        setSubmitting(false)
    }

    return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
    >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
            return <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-3" controlId="login-form">
                    <FormLabel>Fecha de retiro</FormLabel>
                    <FormControl
                        type="date"
                        as={Field}
                        name="consumed_date"
                        controlid="consumed_date"
                        placeholder="Fecha de retiro"
                        autoComplete={"off"}
                        onInput={handleAutofillChange}
                        isInvalid={touched.consumed_date && !!errors.consumed_date}
                    />
                    <FormControl.Feedback type="invalid">
                        {errors.consumed_date}
                    </FormControl.Feedback>
                </FormGroup>
                <FormGroup className="mb-3" controlId="login-form">
                    <FormLabel>Stock Retirado</FormLabel>
                    <FormControl
                        type="numeric"
                        as={Field}
                        name="stock_consumed"
                        controlid="stock_consumed"
                        placeholder="Unidades Retiradas"
                        autoComplete={"off"}
                        onInput={handleAutofillChange}
                        isInvalid={touched.stock_consumed && !!errors.stock_consumed}
                    />
                    <FormControl.Feedback type="invalid">
                        {errors.stock_consumed}
                    </FormControl.Feedback>
                </FormGroup>
                {isSubmitting && <Spinner />}
                <Button variant="success" type="submit" disabled={isSubmitting}>
                    Almacenar
                </Button>
            </Form>
        }
        }
    </Formik>

}

export default FormRemoveStock