import React, { useState, useEffect, useMemo, useRef } from 'react'
import * as Yup from 'yup'
import { Field, Form as Fik, Formik } from 'formik'
import { ListGroup, Form, InputGroup, Button, ToggleButton, ToggleButtonGroup, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import {LuPackageCheck, LuPackageX} from 'react-icons/lu'
const CheckList = ({ ingredients, saveIngredients, itemId }) => {
    const defaultValues = useMemo(() => {
        return {
            order_item_id: itemId,
            items: ingredients.map((ing) => ({
                recipe_ingredient_id: ing.id,
                description: '',
                quantity: ing.quantity,
            })),
        };
    }, [ingredients, itemId]);

    const [formValues, setFormValues] = useState(defaultValues);

    const schemaValidation = Yup.object().shape({
        order_item_id: Yup.number().required('El ID del order_item es obligatorio'),
        items: Yup.array().of(
            Yup.object().shape({
                recipe_ingredient_id: Yup.number().required('El ID del ingrediente es obligatorio'),
                description: Yup.string(),
                quantity: Yup.number()
                    .required('La cantidad es obligatoria')
                    .min(0, 'La cantidad debe ser mayor o igual a cero')
                    .test('es-decimal', 'Debe ser un número decimal con hasta 2 decimales', (value) => {
                        return /^[0-9]+(\.[0-9]{1,2})?$/.test(value);
                    }),
            })
        ),
    });

    const handleInput = (evt) => {
        let inputId = evt.target.name.replaceAll("text-", "")
        let itemIdx = formValues.items.findIndex((itm)=> parseInt(itm.recipe_ingredient_id) === parseInt(inputId))
        let itemsTmp = [
            ...formValues.items,
        ]
        let key = (evt.target.name.indexOf("text-")>-1)?"description":"quantity"
        itemsTmp[itemIdx] = {
            ...itemsTmp[itemIdx],
            [key]: evt.target.value
        }
        setFormValues({
            ...formValues,
            items: itemsTmp
        })
    }

    const saveIngChanges = (values, { setSubmitting }) => {
        setSubmitting(true)
        saveIngredients(formValues)
        setSubmitting(false)
    }

    return <Formik
        initialValues={formValues}
        validationSchema={schemaValidation}
        onSubmit={saveIngChanges}
    >
        {({ handleSubmit, isSubmitting, errors, touched }) => {
            return <Fik onSubmit={handleSubmit}>
                <ListGroup>
                <ListGroup.Item active>
                    Checklist de ingredientes
                </ListGroup.Item>
                {ingredients.length > 0 && ingredients.map((ing, idx) => <ListGroup.Item key={idx}>
                    <InputGroup>
                        <InputGroup.Text>{ing.inventory_input.name} ({ing.inventory_measure.name})</InputGroup.Text>
                        <Form.Control as={Field} type={"number"} onInput={handleInput} readOnly name={ing.id} id={ing.id}
                                      aria-label={ing.inventory_input.name}  defaultValue={ing.quantity} />
                        <CheckboxButton ing={ing} onChange={handleInput} />
                        <Form.Control.Feedback type="invalid">
                            {errors[idx]}
                        </Form.Control.Feedback>
                    </InputGroup>
                </ListGroup.Item>)}
                <ListGroup.Item>
                    <Button type={"submit"} variant={"success"} disabled={isSubmitting}>
                        {isSubmitting && <Spinner />}almacenar</Button>
                </ListGroup.Item>
            </ListGroup>
            </Fik>
        }}
    </Formik>
}

const CheckboxButton = ({onChange, checkedDft, ing}) => {
    const inputRef = useRef(null)
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (checked) {
            inputRef.current.value = "Agregado OK";
        } else {
            inputRef.current.value = "Pendiente de Agregar";
        }
        const inputEvent = new Event("input", { bubbles: true });
        inputRef.current.dispatchEvent(inputEvent);
    }, [checked]);

    return <><ToggleButton
        className="mb-2"
        id={`chb_${ing.id}`}
        type="checkbox"
        variant={checked?"success":"warning"}
        checked={checked}
        value="1"
        onChange={(e) => {
            setChecked(e.currentTarget.checked)
        }}
    >
        {checked?<><LuPackageCheck /> Agregado</>:<><LuPackageX /> Agregar</>}
    </ToggleButton>
        <input
            type={"text"}
            onInput={onChange}
            ref={inputRef}
            style={{
                display:"none"
            }}
            name={`text-${ing.id}`}
            id={ing.id}
            defaultValue={"Pendiente de Agregar"}
            aria-label={ing.inventory_input.name}/>
        </>
}

export default CheckList