import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, FormCheck, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'

const FormGuard = (props) => {
  const [formValues, setFormValues] = useState({
    package_name: props.package_name,
    units_by: props.units_by,
    stock: props.stock??0,
    status: props.status??1,
  });
  const validationSchema = Yup.object().shape({
    package_name: Yup.string().required("Valor requerido").max(20, "Nombre debe ser máximo de 20 caracteres"),
    units_by: Yup.number().required("Valor requerido"),
    stock: Yup.number().default(0),
    status: Yup.boolean().default(true)
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
          return <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Nombre Empaque</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="package_name"
                controlid="package_name"
                placeholder="Nombre Empaque"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.package_name && !!errors.package_name}
              />
              <FormControl.Feedback type="invalid">
                {errors.package_name}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Unidades en el empaque</FormLabel>
              <FormControl
                  type="numeric"
                  as={Field}
                  name="units_by"
                  controlid="units_by"
                  placeholder="Unidades en el Empaque"
                  autoComplete={"off"}
                  onInput={handleAutofillChange}
                  isInvalid={touched.units_by && !!errors.units_by}
              />
              <FormControl.Feedback type="invalid">
                {errors.units_by}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Stock</FormLabel>
              <FormControl
                  type="numeric"
                  as={Field}
                  name="stock"
                  controlid="stock"
                  placeholder="Unidades en stock"
                  readOnly={true}
                  autoComplete={"off"}
                  onInput={handleAutofillChange}
                  isInvalid={touched.stock && !!errors.stock}
              />
              <FormControl.Feedback type="invalid">
                {errors.stock}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="active-account-form">
              <FormLabel>Estado</FormLabel>
            <Field name="status">
              {({ field, form }) => (
                  <FormCheck
                      {...field}
                      type="switch"
                      id="status"
                      name="status"
                      controlid="status"
                      placeholder="Estado"
                      value={field.value}
                      defaultChecked={field.value!==0}
                      onChange={(event) => {
                        form.setFieldValue(event.target.name, event.target.checked);
                      }}
                      autoComplete={"off"}
                      onInput={handleAutofillChange}
                      isInvalid={touched.status && !!errors.status}
                  />
              )}
            </Field>
            </FormGroup>
            {isSubmitting && <Spinner />}
            <Button variant="success" type="submit" disabled={isSubmitting}>
              Almacenar
            </Button>
          </Form>
        }
        }
      </Formik>

}

export default FormGuard