import { Table } from 'react-bootstrap'
import React, { useState, useEffect} from 'react'
const TableSupply = ({ packageData })=> {
    const [suppliesInfo, setSuppliesInfo] = useState([])
    useEffect(()=>{
        let sortSupplies = packageData.supplies.map((itm)=>itm)
        sortSupplies.reverse()
        setSuppliesInfo(sortSupplies)
    },[])
    return <Table striped bordered hover responsive>
        <thead>
        <tr>
            <th>ID</th>
            <th>Fecha de Ingreso</th>
            <th>Cantidad</th>
            <th>Usuario Ingresa</th>
        </tr>
        </thead>
        <tbody>
        {suppliesInfo.map((g, idx) => {
            return <tr key={idx}>
                <td>
                    {g.id}
                </td>
                <td>
                    {g.supply_date}
                </td>
                <td>
                    {g.stock}
                </td>
                <td>
                    {g.user !== null ? `${g.user.name}` :`N/A`}
                </td>
            </tr>
        })}
        </tbody>
    </Table>
}

export default TableSupply