import React, {useMemo, useCallback, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Card, Row, Col } from 'react-bootstrap'
import {get} from "../../store/orderSlice";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const HomePage = () => {
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.user)
    const [loading, setLoading] = useState(true)
    const [statusQty, setStatusQty] = useState([])
    const orderState = useSelector((state) => state.orders)
    const configs = useMemo(()=>({
        headers:{
            'content-type': 'application/json',
            'Authorization': `Bearer ${userState.user.token}`
        }
    }), [userState])

    const getData = useCallback(async ()=>{
        const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/order", configs)
        dispatch(get(response.data))
    },[dispatch, configs])

    useEffect(()=>{
        setLoading(true)
        let promise = getData()
        promise.then(()=>{
            setLoading(false)
            let conteoPorEstado = []
            orderState.orders.forEach((order)=>{
                order.order_items.forEach((itm)=>{
                    const estadoItem = itm.status;
                    if (conteoPorEstado[estadoItem]) {
                        conteoPorEstado[estadoItem]++;
                    } else {
                        conteoPorEstado[estadoItem] = 1;
                    }
                })
            })
            setStatusQty(conteoPorEstado)
        })
    },[getData])

    return <>
        <h3 className={"mb-4 mt-2"}>Bienvenido al sistema de inventario</h3>
        <hr />
        <p>En este sistema podrás gestionar todo el proceso inventarios de materia prima y la gestión del proceso de preparación de formulas.</p>
        <p>A continuación te podrás ver un resumen del estado de producción:</p>
        <Row>
            <Col>
                <Card>
                    <Card.Header></Card.Header>
                    <Card.Body>
                        {Object.keys(statusQty).length > 0 && <BarChart data={statusQty}/>}
                    </Card.Body>
                </Card>
            </Col>

        </Row>
    </>
}
const BarChart = ({ data }) => {
    const chartData = {
        labels: Object.keys(data),
        datasets: [
            {
                label: 'Cantidad de Productos',
                data: Object.values(data),
                backgroundColor: 'rgba(1, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Cantidad de Productos',
                },
            },
        },
    };
    return (
        <div>
            <Bar options={options} data={chartData} />
        </div>
    );
};

export default HomePage