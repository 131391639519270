import React, { useState } from 'react'
import { Field, Form as Fik, Formik } from 'formik'
import { Accordion, Button,  FormGroup, FormLabel, Spinner, Table } from 'react-bootstrap'
const NoteList = ({ itemId, saveNote, notes, orderOpen }) => {
    const [formValues, setFormValues] = useState({
        order_item_id: itemId
    });
    const initialFormValues = {
        order_item_id: itemId,
        note: '', // Agregamos un campo para la nota
    };
    const handleInput = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const saveNoteInternal = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        saveNote(values);
        resetForm(); // Restablecer el formulario después de guardar
        setSubmitting(false);
    }

    return <Formik
        initialValues={initialFormValues}
        onSubmit={saveNoteInternal}
    >
        {({ handleSubmit, isSubmitting }) => {
            return <Accordion >
                {orderOpen? <Accordion.Item eventKey="0">
                    <Accordion.Header>Nueva Nota de producción</Accordion.Header>
                    <Accordion.Body>
                        <Fik onSubmit={handleSubmit}>
                            <FormGroup className="mb-3" controlId="textarea-form">
                                <FormLabel>Nota</FormLabel>
                                <Field
                                    name="note"
                                    id="note"
                                    controlid="note"
                                    as="textarea"
                                    className={"form-control"}
                                    rows={4}
                                    autoComplete={"off"}
                                    placeholder="Ingresa tu nota de producción aquí"
                                    onInput={handleInput}
                                />
                            </FormGroup>

                            <Button variant="success" className={"mr-2"} type="submit" disabled={isSubmitting}>
                                {isSubmitting && <Spinner/>}
                                Almacenar
                            </Button>
                        </Fik>
                    </Accordion.Body>
                </Accordion.Item>:<></>}
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Notas de Producción</Accordion.Header>
                    <Accordion.Body>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Fecha</th>
                                <th>Usuario</th>
                                <th>Nota</th>
                            </tr>
                            </thead>
                            <tbody>
                            {notes && notes.map((n)=><tr key={n.id}>
                                <td>{n.id}</td>
                                <td>{n.created_at}</td>
                                <td>{n.user.name}</td>
                                <td>{n.note}</td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        }}
    </Formik>
}

export default NoteList
