import {Accordion, Table} from 'react-bootstrap'
const IngredientChangeList = ({ingredientsList}) => {
    return <Accordion >
        <Accordion.Item eventKey="0">
            <Accordion.Header>Ingredientes consumidos</Accordion.Header>
            <Accordion.Body>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Ingrediente</th>
                        <th>Cantidad</th>
                        <th>Comentario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ingredientsList.map((ing, idx)=>{
                        return <tr>
                            <td>{idx+1}</td>
                            <td>{ing.recipe_ingredient.inventory_input.name}</td>
                            <td>{ing.quantity}</td>
                            <td>{ing.description}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default IngredientChangeList