import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  category: [],
  type: [],
  measure: [],
  measureConversion: [],
  input: [],
  batch: [],
}

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    getCategories: (state, action) => {
      state.category = action.payload
    },
    saveCategory: (state, action) => {
      let index = state.category.findIndex((g)=>(g.id === action.payload.id))
      state.category[index] = action.payload
    },
    getTypes: (state, action) => {
      state.type = action.payload
    },
    saveType: (state, action) => {
      let index = state.type.findIndex((g)=>(g.id === action.payload.id))
      state.type[index] = action.payload
    },
    getMeasures: (state, action) => {
      state.measure = action.payload
    },
    saveMeasure: (state, action) => {
      let index = state.measure.findIndex((g)=>(g.id === action.payload.id))
      state.measure[index] = action.payload
    },
    getMeasuresConversion: (state, action) => {
      state.measureConversion = action.payload
    },
    saveMeasuresConversion: (state, action) => {
      let index = state.measureConversion.findIndex((g)=>(g.id === action.payload.id))
      state.measureConversion[index] = action.payload
    },
    getInput: (state, action) => {
      state.input = action.payload
    },
    saveInput: (state, action) => {
      let index = state.input.findIndex((g)=>(g.id === action.payload.id))
      state.input[index] = action.payload
    },
    getBatch: (state, action) => {
      state.batch = action.payload
    },
    saveBatch: (state, action) => {
      //state.batch.
      //let index = state.batch.findIndex((g)=>(g.id === action.payload.id))
      //state.batch[index] = action.payload
    },
  },
})

export const {
  getCategories,
  saveCategory,
  getTypes,
  saveType,
  getMeasures,
  saveMeasure,
  getInput,
  saveInput,
  getBatch,
  saveBatch,
  getMeasuresConversion,
  saveMeasuresConversion
} = inventorySlice.actions
export default inventorySlice.reducer