import { Table, Badge } from 'react-bootstrap'
import React, { useState, useEffect} from 'react'
const TableConsume = ({ packageData })=> {
    const [consumesInfo, setconsumesInfo] = useState([])
    useEffect(()=>{
        let sortSupplies = packageData.consumes.map((itm)=>itm)
        sortSupplies.reverse()
        setconsumesInfo(sortSupplies)
    },[])
    return <Table striped bordered hover responsive>
        <thead>
        <tr>
            <th>ID</th>
            <th>Fecha de Consumo</th>
            <th>Cantidad Consumida</th>
            <th>Estado</th>
            <th>Lote</th>
            <th>Usuario reporta</th>
        </tr>
        </thead>
        <tbody>
        {consumesInfo.map((g, idx) => {
            return <tr key={idx}>
                <td>{g.id}</td>
                <td>{g.consumed_date}</td>
                <td>{g.stock_consumed}</td>
                <td>{g.spoilt === 1 ? <Badge bg={"danger"}>Dañado</Badge>:<Badge bg={"success"}>Usado</Badge>}</td>
                <td>{g.batch !== null ? `${g.batch.batch_serial}` :`N/A`}</td>
                <td>{g.user !== null ? `${g.user.name}` :`N/A`}</td>
            </tr>
        })}
        </tbody>
    </Table>
}

export default TableConsume