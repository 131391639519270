import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, FormCheck, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'

const FormGuard = (props) => {
    const [formValues, setFormValues] = useState({
        package_id: props.packageId,
        supply_date: null,
        stock: 0,
    });
    const validationSchema = Yup.object().shape({
        supply_date: Yup.date().required("Valor requerido"),
        stock: Yup.number().default(0),
    });
    const handleAutofillChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        setFormValues(values)
        await props.handleSubmit(values)
        setSubmitting(false)
    }

    return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
    >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
            return <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-3" controlId="login-form">
                    <FormLabel>Fecha de ingreso</FormLabel>
                    <FormControl
                        type="date"
                        as={Field}
                        name="supply_date"
                        controlid="supply_date"
                        placeholder="Fecha de ingreso"
                        autoComplete={"off"}
                        onInput={handleAutofillChange}
                        isInvalid={touched.supply_date && !!errors.supply_date}
                    />
                    <FormControl.Feedback type="invalid">
                        {errors.supply_date}
                    </FormControl.Feedback>
                </FormGroup>
                <FormGroup className="mb-3" controlId="login-form">
                    <FormLabel>Stock</FormLabel>
                    <FormControl
                        type="numeric"
                        as={Field}
                        name="stock"
                        controlid="stock"
                        placeholder="Unidades en stock"
                        autoComplete={"off"}
                        onInput={handleAutofillChange}
                        isInvalid={touched.stock && !!errors.stock}
                    />
                    <FormControl.Feedback type="invalid">
                        {errors.stock}
                    </FormControl.Feedback>
                </FormGroup>
                {isSubmitting && <Spinner />}
                <Button variant="success" type="submit" disabled={isSubmitting}>
                    Almacenar
                </Button>
            </Form>
        }
        }
    </Formik>

}

export default FormGuard