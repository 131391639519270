import React, {useState} from 'react'
import { Button, Container, Spinner, Table, Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import PopoverWrapper from "../../components/Popover";
import { BiPlus } from 'react-icons/bi'
import { MdRemoveCircleOutline } from 'react-icons/md'
import FormAddStock from "./FormAddStock";
import FormRemoveStock from "./FormRemoveStock";
import ModalOption from "../../components/Modal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TableSupply from "./ListPackagesSuplies";
import TableConsume from "./ListPackagesConsumes";
const List =(props) =>{
  const packagesState = useSelector((state) => state.packages)
  const [showList, setShowList] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(false)
  const handleEdit = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleEdit(packagesState.packages[indexGuard])
  }
  const handleHistory = (evt) => {
    setSelectedPackage(packagesState.packages[evt.target.dataset.index])
    setShowList(true)
  }
  const closeModalList = () => setShowList(false)
  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }
  return <>
  <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>Empaque</th>
      <th>Unidades en empaque</th>
      <th>Stock</th>
      <th>Estado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {packagesState !== undefined && packagesState.packages.map((g, idx)=>(<tr key={idx}>
      <td>{g.id}</td>
      <td>{g.package_name}</td>
      <td>{g.units_by}</td>
      <td>
        {g.stock}{"  "}
        <PopoverWrapper
            caller={<BiPlus />}
            title={"Agregar Empaques al inventario"}
            placement={"right"}
        >
          <FormAddStock  handleSubmit={props.addPackage} packageId={g.id}/>
        </PopoverWrapper>
        {" "}
        <PopoverWrapper
            caller={<MdRemoveCircleOutline />}
            variantCaller={"danger"}
            title={"Retirar Empaques dañados del inventario"}
            placement={"right"}
        >
          <FormRemoveStock  handleSubmit={props.removePackage} packageId={g.id}/>
        </PopoverWrapper>
      </td>
      <td><Badge bg={g.status === 1 ? "success" : "danger"}>{g.status === 1 ? "activo" : "inactivo"}</Badge></td>
      <td>
        <Button variant={"warning"} data-index={idx} className={"text-light"} onClick={handleEdit}>Editar</Button>
        {" "}
        <Button variant={"info"} data-index={idx} className={"text-light"} onClick={handleHistory}>Ver Historial</Button>
      </td>
    </tr>))}
    </tbody>
  </Table>
    <ModalOption show={showList} setClose={closeModalList} title={"Stock empaques disponible"} size={"xl"}>
      <Tabs
          defaultActiveKey="suply"
          id="tab-packages"
          className="mb-3"
      >
        <Tab eventKey="suply" title="Insumos">
          <TableSupply packageData={selectedPackage} />
        </Tab>
        <Tab eventKey="consume" title="Consumos">
          <TableConsume packageData={selectedPackage} />
        </Tab>
      </Tabs>
    </ModalOption>
  </>
}

export default List