import { Badge, Button, Container, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const List =(props) =>{
  const permissionsState = useSelector((state) => state.inventory)
  const handleEdit = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleEdit(permissionsState.input[indexGuard])
  }
  const handleShowList = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleShowList(permissionsState.input[indexGuard])
  }
  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }
  return <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>Nombre</th>
      <th>Categoría</th>
      <th>Cantidad en stock</th>
      <th>Cantidad minima en stock</th>
      <th>Vencimiento Cercano</th>
      <th>Estado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {permissionsState !== undefined && permissionsState.input.map((g, idx)=> {
      const currentBatchs =  permissionsState.batch.ready.filter((b)=> (parseInt(b.input_id) === parseInt(g.id)))
      const date = new Date()
      let minDate= new Date()
      let stock = 0
      if(currentBatchs.length > 0){
        minDate = currentBatchs.reduce((objetoActual, objeto) => Math.abs(objetoActual.expiration_date - date) < Math.abs(objeto.expiration_date - date) ? objetoActual : objeto);
        stock = currentBatchs.reduce((total, producto) => total + producto.quantity, 0);
      }
      return <tr key={idx}>
        <td>{g.id}</td>
        <td>{g.name}</td>
        <td>{g.category.name}</td>
        <td>{stock}</td>
        <td>{g.min_inventory} {g.measure.name}(s)</td>
        <td>{minDate.expiration_date} <Badge bg={"warning"}>{minDate.serial}</Badge></td>
        <td><Badge bg={g.active == "1" ? "success" : "danger"}>{g.active == "1" ? "activo" : "inactivo"}</Badge></td>
        <td>
          <Button variant={"info"} data-index={idx} className={"text-light mx-1"} onClick={handleShowList}>Ver
            Unidades</Button>
          <Button variant={"warning"} data-index={idx} className={"text-light  mx-1"}
                  onClick={handleEdit}>Editar</Button>
        </td>
      </tr>
    })}
    </tbody>
  </Table>
}

export default List