import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'

const FormOrder = (props) => {
  const userState = useSelector((state) => state.user)
  const [formValues, setFormValues] = useState({
    user_id: userState.user.data.id,
    programed_production: props.orderInfo.programed_production
  });

  const validationSchema = Yup.object().shape({
    programed_production: Yup.date().min(new Date(), 'La fecha debe ser mayor o igual a hoy'),
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
          return <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlid="login-form">
              <FormLabel>Fecha programada de producción</FormLabel>
              <FormControl
                type="date"
                as={Field}
                name="programed_production"
                controlid="programed_production"
                placeholder="Fecha programada de producción"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.programed_production && !!errors.programed_production}
              />
              <FormControl.Feedback type="invalid">
                {errors.programed_production}
              </FormControl.Feedback>
            </FormGroup>

            {isSubmitting && <Spinner />}
            <Button variant="success" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner />}
              Almacenar
            </Button>
          </Form>
        }
        }
      </Formik>

}

export default FormOrder