import { Button, Container, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const List =(props) =>{
  const guardState = useSelector((state) => state.recipes)
  const [currRecipe, setCurrRecipe] = useState(null)
  const handleEdit = (evt) => {
    const indexGuard = evt.target.dataset.index;
    setCurrRecipe(indexGuard)
    props.handleEdit(guardState.recipes[indexGuard])
  }
  const handleSteps = (evt) => {
    const indexGuard = evt.target.dataset.index;
    setCurrRecipe(indexGuard)
    props.handleSteps(guardState.recipes[indexGuard])
  }

  const handleIngredients = (evt) => {
    const indexGuard = evt.target.dataset.index;
    setCurrRecipe(indexGuard)
    props.handleIngredients(guardState.recipes[indexGuard])
  }
  useEffect(()=>{
    if(!props.isNew){
      props.updateEditable(guardState.recipes[currRecipe])
    }
  },[guardState.recipes, currRecipe, props])
  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }

  return <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>Nombre</th>
      <th>Descripción</th>
      <th>Pasos</th>
      <th>Ingredientes</th>
      <th>Peso</th>
      <th>Creado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {guardState.recipes.map((g, idx) => (<tr key={idx}>
      <td>{g.id}</td>
      <td>{g.name}</td>
      <td>{g.description}</td>
      <td><Button variant={"info"} className={"text-light"} data-index={idx} onClick={handleSteps}>Ver
        Preparación</Button></td>
      <td><Button variant={"secondary"} className={"text-light"} data-index={idx} onClick={handleIngredients}>Ver
        Ingredientes</Button></td>
      <td>{g.yield} {g.inventory_measure?.name}(s)</td>
      <td>{new Date(Date.parse(g.created_at)).toLocaleDateString()}</td>
      <td><Button variant={"warning"} data-index={idx} className={"text-light"} onClick={handleEdit}>Editar</Button>
      </td>
    </tr>))}
    </tbody>
  </Table>
}

export default List