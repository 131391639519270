import {useEffect, useState} from 'react'
import { Button, Container, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const List =(props) =>{
  const orderState = useSelector((state) => state.orders)
  const [curr, setCurr] = useState(0)
  const handleEdit = (evt) => {
    const indexGuard = evt.target.dataset.index;
    setCurr(indexGuard)
    props.handleEdit(orderState.orders[indexGuard])
  }

  const handleClose = (evt) => {
    if(window.confirm("Deseas cerrar la orden? esta acción es irreversible")){
      const indexGuard = evt.target.dataset.index;
      setCurr(indexGuard)
      props.handleEdit(orderState.orders[indexGuard])
      props.store({
        "open":false
      })
    }

  }

  const handleProducts = (evt) => {
    const indexGuard = evt.target.dataset.index;
    setCurr(indexGuard)
    props.handleProducts(orderState.orders[indexGuard])
  }

  useEffect(()=>{
    props.updateEditable(orderState.orders[curr])
  },[orderState])

  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }
  return <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>Número de Orden</th>
      <th>Jefe de Planta</th>
      <th>Fecha programada de producción</th>
      <th>Productos</th>
    </tr>
    </thead>
    <tbody>
    {orderState !== undefined && orderState.orders.map((g, idx)=>(<tr key={idx}>
      <td>{g.id}</td>
      <td>{g.user?.name}</td>
      <td>{g.programed_production}</td>
      <td>
        <Button variant={"info"} className={"text-light m-1"} onClick={handleProducts} data-index={idx}>Ver Productos</Button>
        {parseInt(g.open) === 1  && <Button variant={"warning"} className={"text-light m-1"} onClick={handleEdit} data-index={idx}>Editar</Button>}
        {parseInt(g.open) === 1  && <Button variant={"danger"} className={"text-light m-1"} onClick={handleClose} data-index={idx}>Cerrar Orden</Button>}
      </td>
    </tr>))}
    </tbody>

  </Table>
}

export default List