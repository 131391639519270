import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { get, save } from '../../store/RecipeSlice'
import { getInput, getMeasures } from '../../store/InventorySlice'
import CreateButton from '../../components/CreateButton'
import List from './List'
import ModalOption from '../../components/Modal'
import FormGuard from './Form'
import ToastAlerts from '../../components/ToastAlerts'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdError } from 'react-icons/md'
import ListSteps from './ListSteps'
import ListIngredients from './ListIngredients'

const RecipePage = () => {
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [showSteps, setShowSteps] = useState(false)
  const [showIngredients, setShowIngredients] = useState(false)
  const [guardEditable, setGuardEditable] = useState({  })
  const [toastData, setToastData] = useState({ title:"", message:"", variant:"success" })
  const [showToast, setShowToast] = useState( false )
  const [isNew, setIsNew] = useState( true )
  const closeModal = ()=> {
    setShow(false)
  }
  const closeModalSteps = ()=> {
    setShowSteps(false)
  }
  const closeModalIngredients = ()=> {
    setShowIngredients(false)
  }
  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`
    }
  }), [userState])

  const getData = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kitchen-recipes", configs)

    dispatch(get(response.data))
  },[dispatch, configs])

  const getDataMeasures = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-measures", configs)

    dispatch(getMeasures(response.data))
  },[dispatch, configs])

  const getDataInputs = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-input", configs)

    dispatch(getInput(response.data))
  },[dispatch, configs])

  useEffect(()=>{
    setLoading(true)
    let promise = getData()
    promise.then(()=>{
      getDataMeasures().then(()=>{
        getDataInputs().then(()=>{
          setLoading(false)
        })
      })

    })
  },[getData,getDataMeasures,getDataInputs])

  const newGuardForm = () => {
    updateEditable(null)
    setIsNew(true)
    setShow(true)
  }
  const handleEdit = (guard) => {
    updateEditable(guard)
    setShow(true)
    setIsNew(false)
  }

  const handleSteps = (guard) => {
    setShowSteps(true)
    updateEditable(guard)
  }
  const handleIngredients = (guard) => {
    setShowIngredients(true)
    updateEditable(guard)
  }

  const updateEditable = (guard)=>{
    setGuardEditable(guard)
  }

  const store = async (formData) => {
    try {
      let res
      if(guardEditable?.id === undefined){
        res = await axios.post(process.env.REACT_APP_USER_API_URL + "/kitchen-recipes", formData,configs)
      }else{
        res = await axios.put(process.env.REACT_APP_USER_API_URL + `/kitchen-recipes/${guardEditable.id}`, formData,configs)
      }
      dispatch(save(res.data))
      getData().then(()=>{
        setToastData({
          title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
          message: 'Proceso ejecutado satisfactoriamente!',
          variant: 'success',
        })
        setShowToast(true)
        setShow(false)
      })
    }catch (e){
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
        message: 'Se presentó un error en el proceso! '+ e.toString(),
        variant: 'danger',
      })
      setShowToast(true)
    }
  }

  const storeStep = async (formData) => {
    try {
      let res
      if(formData.id === undefined){
        res = await axios.post(process.env.REACT_APP_USER_API_URL + "/kitchen-recipe-steps", formData,configs)
      }else{
        res = await axios.put(process.env.REACT_APP_USER_API_URL + `/kitchen-recipe-steps/${formData.id}`, formData,configs)
      }
      dispatch(save(res.data))
      getData().then(()=>{
        setToastData({
          title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
          message: 'Proceso ejecutado satisfactoriamente!',
          variant: 'success',
        })
        setShowToast(true)
        setShowSteps(false)
      })
    }catch (e){
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
        message: 'Se presentó un error en el proceso!',
        variant: 'danger',
      })
      setShowToast(true)
      console.log(e)
    }
  }

  return <>
  <h3 className={"mb-4 mt-2"}>Formulas</h3>
    <hr />
    <List loading={loading} isNew={isNew} handleEdit={handleEdit} updateEditable={updateEditable} handleSteps={handleSteps} handleIngredients={handleIngredients}/>
    <CreateButton clickEvent={newGuardForm}/>
    <ModalOption show={show} setClose={closeModal} title={"Administrar Formula"}>
      <FormGuard {...guardEditable} handleSubmit={store} />
    </ModalOption>
    <ModalOption show={showSteps} setClose={closeModalSteps} title={"Preparación Formula"}>
      <ListSteps recipe={guardEditable} handleSubmitStep={storeStep}/>
    </ModalOption>
    <ModalOption show={showIngredients} setClose={closeModalIngredients} title={"Ingredientes de Formula"} size={"lg"}>
      <ListIngredients recipe={guardEditable} getRecipes={getData} />
    </ModalOption>
    <ToastAlerts {...toastData} show={showToast} setShowToast={setShowToast}/>
  </>
}

export default RecipePage