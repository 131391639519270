import React from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'

const   FormAddSteps = (props) => {
  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Valor requerido")
  });

  const handleAutofillChange = (event) => {
    props.setStep({
      ...props.recipeStep,
      recipe_id: props.recipeId,
      [event.target.name]: event.target.value
    })
    console.log(props.recipeStep)
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    await props.handleSubmit(values, props.id)
    setSubmitting(false)
  }

  const handleReset = (evt) => {
    props.setStep({
      recipe_id: props.recipeId,
      id: undefined,
      description: undefined
    })
  }

  return <Formik
    initialValues={{
      recipe_id: props.recipeId,
      id: props.recipeStep.id,
      description: props.recipeStep.description
  }}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
    onReset={handleReset}
  >
    {({ handleSubmit, isSubmitting, errors }) => (
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3" controlId="textarea-form">
          <FormLabel>Descripción</FormLabel>
          <Field
            name="description"
            controlid="description"
            as="textarea"
            className={"form-control"}
            rows={4}
            autoComplete={"off"}
            placeholder="Enter your text here"
            onInput={handleAutofillChange}
            defaultValue={props.recipeStep.description || ""}
          />
          <FormControl.Feedback type="invalid">
            {errors.description}
          </FormControl.Feedback>
        </FormGroup>

        <Button variant="success" className={"mr-2"} type="submit" disabled={isSubmitting}>
          {isSubmitting && <Spinner />}
          Almacenar
        </Button>{" "}
        <Button variant="info" className={"mr-2 text-light"} type="reset" disabled={isSubmitting}>
          {isSubmitting && <Spinner />}
          Nuevo
        </Button>
      </Form>
    )}
  </Formik>

}

export default FormAddSteps