import { Button, Form, InputGroup, Spinner, Table, Badge } from 'react-bootstrap'
import React, { useMemo, useState } from 'react'
import ToastAlerts from '../../components/ToastAlerts'
import CreateButton from '../../components/CreateButton'
import {  useSelector } from 'react-redux'
import { MdError } from 'react-icons/md'
import { AiFillCheckCircle } from 'react-icons/ai'
import axios from 'axios'
import BatchList from "./BatchList";
import ModalOption from "../../components/Modal";
import CheckList from "./CheckList";
import IngredientChangeList from "./IngredientChangeList";
import NotesList from "./NotesList";

const ListProducts =({ orderInfo, reloadOrders }) =>{
  const inventoryState = useSelector((state) => state.inventory)
  const recipeState = useSelector((state) => state.recipes)
  const userState = useSelector((state) => state.user)
  const [toastData, setToastData] = useState({ title:"", message:"", variant:"success" })
  const [showToast, setShowToast] = useState( false )
  const [newRecord , setNewRecord] = useState(false )
  const [requestChecklist , setRequestChecklist] = useState(false )
  const [editId, setEditId] = useState(0)
  const [orderItem, setOrderItem] = useState({
    order_id: orderInfo.id
  })
  const [saving , setSaving] = useState(false )
  const status = ['Pendiente', 'En Producción', 'Producto Sin Empaque', 'Producto Empacado', 'Despachado']

  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`,
      'Accept': `*/*`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
      'Access-Control-Allow-Headers': 'Content-Type',
      withCredentials: true,
    }
  }), [userState])

  const saveIngredientsChanges = async (values) =>{
    try{
      await axios.post(process.env.REACT_APP_USER_API_URL + "/order-item-consume", values, configs)
      await reloadOrders()
      setToastData({
        title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
        message: 'Proceso ejecutado satisfactoriamente!',
        variant: 'success',
      })
      await saveItem()
      setShowToast(true)

    }catch (ex) {
      let errorMessage = ex.message
      if(ex.response?.data?.error !== undefined) {
        errorMessage = ex.response?.data?.error
      }
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
        message: <>Se presentó un error en el proceso! <strong>{errorMessage}</strong></>,
        variant: 'danger',
      })
      setShowToast(true)
    }

  }

  const saveItem = async () => {
    setSaving(true)
    let answer
    try {
      if (editId === 0) {
        answer = await axios.post(process.env.REACT_APP_USER_API_URL + "/order-item", orderItem, configs)
      } else {
        answer = await axios.put(process.env.REACT_APP_USER_API_URL + "/order-item/"+editId, orderItem, configs)
      }
      await reloadOrders()
      setToastData({
           title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
           message: 'Proceso ejecutado satisfactoriamente!',
           variant: 'success',
      })
      setEditId(0)
      setNewRecord(false)
      setOrderItem({
        order_id: orderInfo.id
      })
      setShowToast(true)
    }catch (ex){
      let errorMessage = ex.message
      if(ex.response?.data?.error !== undefined) {
        errorMessage = ex.response?.data?.error
      }
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
        message: <>Se presentó un error en el proceso! <strong>{errorMessage}</strong></>,
        variant: 'danger',
      })
      setShowToast(true)
    }
    setSaving(false)
  }



  const saveNote = async (noteValues) => {
    setSaving(true)
    let answer
    try {
      answer = await axios.post(process.env.REACT_APP_USER_API_URL + "/order-item-notes", noteValues, configs)

      await reloadOrders()
      setToastData({
        title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
        message: 'Proceso ejecutado satisfactoriamente!',
        variant: 'success',
      })
      setEditId(0)
      setNewRecord(false)
      setOrderItem({
        order_id: orderInfo.id
      })
      setShowToast(true)
    }catch (ex){
      let errorMessage = ex.message
      if(ex.response?.data?.error !== undefined) {
        errorMessage = ex.response?.data?.error
      }
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
        message: <>Se presentó un error en el proceso! <strong>{errorMessage}</strong></>,
        variant: 'danger',
      })
      setShowToast(true)
    }
    setSaving(false)
  }

  const handleEdit = (evt) => {
    setEditId(evt.target.dataset.id)
    setOrderItem({
      order_id: orderInfo.id
    })
    setNewRecord(false)
  }

  const productionChecklistTrigger = (evt) => {
    setRequestChecklist(true)

  }

  const editIngredient = (evt) => {
    let name = evt.target.name
    if(name === "status" && evt.target.value === "En Producción"){
      setOrderItem({
        ...orderItem,
        [name]: evt.target.value
      })
      productionChecklistTrigger(evt)
    }else {
      setRequestChecklist(false)
      setOrderItem({
        ...orderItem,
        [name]: evt.target.value
      })
    }
  }
  return <>
    <Table striped bordered hover responsive>
      <thead>
      <tr>
        <th>Cantidad</th>
        <th>Formula</th>
        <th>Estado</th>
        <th>Creado</th>
        <th>Lote Producido</th>
        <th>Acciones</th>
      </tr>
      </thead>
      <tbody>
      {orderInfo.order_items.map((g, idx) => {
        return <tr key={idx}>
          <td>
            {parseInt(editId) !== parseInt(g.id) && `${g.quantity} ${g.measure.name}(s)`}
            {parseInt(editId) === parseInt(g.id) && g.status !== "Pendiente" && `${g.quantity} ${g.measure.name}(s)`}
            {parseInt(editId) === parseInt(g.id) && g.status === "Pendiente" && <InputGroup className="mb-3">
              <Form.Control type="number" name={'quantity'} onChange={editIngredient} defaultValue={g.quantity} />

              <select
                className={'form-control'}
                name="measure_id"
                controlid="measure_id"
                defaultValue={g.measure_id}
                placeholder="Unidad de Medida"
                autoComplete={'off'}
                onChange={editIngredient}
              >
                <option value="">Seleccione una medida</option>
                {inventoryState !== undefined && inventoryState.measure.map((q, idx) => <option key={`opti-${idx}`}
                                                                                                value={q.id}>{q.name}</option>)}
              </select>
            </InputGroup>
            }
          </td>
          <td>
            {parseInt(editId) !== parseInt(g.id) && g.recipe.name}
            {parseInt(editId) === parseInt(g.id) && g.status !== "Pendiente" && g.recipe.name}
            {parseInt(editId) === parseInt(g.id) && g.status === "Pendiente" && <InputGroup className="mb-3">
              <select
                className={'form-control'}
                controlid="recipe_id"
                name="recipe_id"
                defaultValue={g.recipe_id}
                placeholder="Formula"
                autoComplete={'off'}
                onChange={editIngredient}
              >
                <option value="">Seleccione una formula</option>
                {recipeState !== undefined && recipeState.recipes.map((q, idx) => <option key={`opti-${idx}`}
                                                                                                value={q.id}>{q.name}</option>)}
              </select>
            </InputGroup>
            }
          </td>
          <td>
            {parseInt(editId) !== parseInt(g.id) && g.status}
            {(g.status === "Producto Sin Empaque" && g.batchs.some((b)=>b.packages.length === 0)) && <Badge className={"m-2"}>Añada empaques a todo el lote</Badge>}
            {parseInt(editId) === parseInt(g.id) && (g.status !== "Producto Sin Empaque"
                    || (g.status === "Producto Sin Empaque" && g.batchs.every((b)=>b.packages.length > 0)))
                && <InputGroup className="mb-3">
              <select
                  className={'form-control'}
                  controlid="status"
                  name="status"
                  defaultValue={g.status}
                  placeholder="Formula"
                  autoComplete={'off'}
                  onChange={editIngredient}
              >
                <option value="">Seleccione un estado</option>
                {status.map((itm, idx) => {
                    return <option key={idx} value={itm}>{itm}</option>
                })}
              </select>
            </InputGroup>
            }
            {parseInt(editId) === parseInt(g.id) && requestChecklist && g.consumed_inputs.length === 0 && <>
              <CheckList ingredients={g.recipe.recipe_ingredients} itemId={g.id} saveIngredients={saveIngredientsChanges} />
            </>}
            {g.consumed_inputs.length > 0 && <IngredientChangeList ingredientsList={g.consumed_inputs} />}
          </td>
          <td>
            {(new Date(g.created_at)).toLocaleString()}
            <NotesList saveNote={saveNote} itemId={g.id} notes={g.notes} orderOpen={orderInfo.open} />
          </td>
          <td>
            {g.batchs.length > 0  && <BatchList batchs={g.batchs} reloadOrders={reloadOrders} status={g.status}/>}
          </td>

          <td>
            {parseInt(editId) !== parseInt(g.id) && orderInfo.open === 1 && g.status !== "Despachado" &&
                <Button variant={'warning'} data-id={g.id} className={'text-light'} onClick={handleEdit}>Editar</Button>}
            {parseInt(editId) === parseInt(g.id) &&
                <Button variant={'success'} data-id={g.id} disabled={saving} className={'text-light  mx-1'}
                        onClick={saveItem}>{saving && <Spinner />} Guardar</Button>}
          </td>
        </tr>
      })}
      {newRecord && editId === 0 && <tr>
        <td>
          <InputGroup className="mb-3">
            <Form.Control type="number" name={"quantity"} onChange={editIngredient} />

            <select
              className={"form-control"}
              name="measure_id"
              controlid="measure_id"
              placeholder="Unidad de Medida"
              autoComplete={"off"}
              onChange={editIngredient}
            >
              <option value="">Seleccione una medida</option>
              {inventoryState !== undefined && inventoryState.measure.map((q, idx) => <option key={`opti-${idx}`}
                                                                                              value={q.id}>{q.name}</option>)}
            </select>
          </InputGroup>

        </td>
        <td>
          <InputGroup className="mb-3">
            <select
                className={'form-control'}
                controlid="recipe_id"
                name="recipe_id"
                placeholder="Formula"
                autoComplete={'off'}
                onChange={editIngredient}
            >
              <option value="">Seleccione una formula</option>
              {recipeState !== undefined && recipeState.recipes.map((q, idx) => <option key={`opti-${idx}`}
                                                                                        value={q.id}>{q.name}</option>)}
            </select>
          </InputGroup>

        </td>
        <td>
          <InputGroup className="mb-3">
            <select
                className={'form-control'}
                controlid="status"
                name="status"
                placeholder="Estado"
                autoComplete={'off'}
                onChange={editIngredient}
                disabled={requestChecklist}
            >
              <option value="">Seleccione un estado</option>
              <option value="Pendiente">Pendiente</option>
            </select>
          </InputGroup>

        </td>

        <td>{(new Date()).toLocaleDateString()}</td>
        <td>N/A</td>
        <td>
          <Button variant={'success'} disabled={saving} className={'text-light  mx-1'}
                  onClick={saveItem}>{saving && <Spinner />} Guardar</Button>

        </td>
      </tr>


      }
      </tbody>

    </Table>
    <ToastAlerts {...toastData} show={showToast} setShowToast={setShowToast}/>
    {!newRecord && orderInfo.open === 1 && <CreateButton position={'absolute'} clickEvent={() => {
      setEditId(0)
      setNewRecord(true)
      setOrderItem({
        order_id: orderInfo.id
      })
    }} />}
  </>
}

export default ListProducts